/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2025 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Link, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import { currentPortal } from 'components/materialTheme/commonTheme'

const useStyles = makeStyles(() => ({
    tooltip: {
        backgroundColor: '#fdfdfd',
        color: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid #3c3c3c',
        borderRadius: '2px',
        padding: '2px 8px',
        '&.MuiTooltip-tooltip': {
            '&.MuiTooltip-tooltipPlacementBottom': {
                marginTop: '2px'
            },
            '&.MuiTooltip-tooltipPlacementTop': {
                marginBottom: '2px'
            },
            '&.MuiTooltip-tooltipPlacementLeft': {
                marginRight: '2px'
            },
            '&.MuiTooltip-tooltipPlacementRight': {
                marginLeft: '2px'
            }
        }
    },
    div: { '&> *': { height: '100%' } }
}))

export default function TooltipComponent({ title, children }: { title: string; children: React.ReactNode }) {
    const firstChild = React.Children.toArray(children)[0]
    if (React.isValidElement(firstChild)) {
        return <React.Fragment>{React.cloneElement(firstChild, { ...firstChild.props, title })}</React.Fragment>
    }
    return <React.Fragment>{children}</React.Fragment>
    // const classes = useStyles()
    // const firstChild = React.Children.toArray(children)[0]
    // const disabled = React.isValidElement(firstChild) ? firstChild.props.disabled : false

    // return (
    //     <Tooltip
    //         classes={{ tooltip: classes.tooltip }}
    //         title={
    //             <React.Fragment>
    //                 <Typography component="div" variant="caption">
    //                     {title}
    //                 </Typography>
    //                 {disabled && currentPortal() === 'drg' && (
    //                     <Typography variant="caption">
    //                         ({gettext('deactivated, please contact')}
    //                         <Link href="mailto:conrad@drg.de"> conrad@drg.de</Link>)
    //                     </Typography>
    //                 )}
    //             </React.Fragment>
    //         }
    //         placement={'bottom-start'}
    //         enterDelay={500}
    //     >
    //         <div className={classes.div}>{children}</div>
    //     </Tooltip>
    // )
}
